const toggleClass = (element, className) => element.classList.toggle(className);
const removeClass = (element, className) => element.classList.remove(className);

function handleAccordionToggle(toggle) {
    toggle.addEventListener('click', () => toggleClass(toggle,'open'));
}

function handleMapZoom(map, continent, cards = []) {
    toggleClass(continent, 'continent-zoom');
    toggleClass(map, 'zoom');
    // close all species cards
    handleSpeciesCardToggle(cards, false, continent, true);
}

function handleSpeciesCardToggle(cards, target, continent, closingMap = false) {
    if (!cards.length > 0) return;
    let card_focus = false;
    cards.forEach(card => {
        // if this card is the one being clicked AND the map isn't being closed
        if (card === target && !closingMap) {
            toggleClass(target, 'card-is-open');
            if (target.classList.contains('card-is-open')) {
                card_focus = true;
            }
        } else {
            removeClass(card, 'card-is-open');
        }
    });
    continent.classList.toggle('card-open-within', card_focus);
}

function initContinents(map, continent) {
    const zoomIn = continent.querySelector('.map-zoom-in');
    const zoomOut = continent.querySelector('.map-zoom-out');
    const cards = Array.from(continent.querySelectorAll('.species-card'));

    zoomIn.addEventListener('click', () => handleMapZoom(map, continent));
    zoomOut.addEventListener('click', () => handleMapZoom(map, continent, cards));

    cards.forEach(card => {
        const cardOpen = card.querySelector('.card-open');
        const cardClose = card.querySelector('.card-close');

        cardOpen.addEventListener('click', () => handleSpeciesCardToggle(cards, card, continent));
        cardClose.addEventListener('click', () => handleSpeciesCardToggle(cards, card, continent));
    });
}

function initMap(map) {
    const accordions = Array.from(map.querySelectorAll('.map-accordion'));
    accordions.forEach(handleAccordionToggle);

    const continents = Array.from(map.querySelectorAll('.continent'));
    continents.forEach(continent => {
        initContinents(map, continent);
    });
}


document.addEventListener('readystatechange', () => {
    if (document.readyState === 'complete') {
        const maps = Array.from(document.querySelectorAll('.interactive-map .map-container'));
        maps.forEach(initMap);
    }
});

